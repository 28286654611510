<template>
  <div>
    <b-card title="Filtros">
      <validation-observer ref="filtrosForm" tag="form">
        <b-row id="filtros">
          <b-col cols="2">
            <b-form-group label="Pesquisar Por" label-for="opcao">
              <b-skeleton v-if="isBusy" type="input" />
              <v-select-pt
                v-else
                :clearable="false"
                :reduce="(opcao) => opcao.id"
                id="opcao"
                :options="opcoesPesquisa"
                v-model="pesquisar.tipoOpcoes"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Descrição" label-for="descricao">
              <b-skeleton v-if="isBusy" type="input" />

              <b-form-input
                v-if="pesquisar.tipoOpcoes == 'nome' && !isBusy"
                id="descricao"
                type="text"
                placeholder="Nome"
                v-model="pesquisar.descricao"
              />

              <b-form-input
                v-if="pesquisar.tipoOpcoes == 'contrato' && !isBusy"
                id="descricao"
                type="text"
                placeholder="Contrato"
                v-model="pesquisar.descricao"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Data Inicial" label-for="dataInicial">
              <b-skeleton v-if="isBusy" type="input" />

              <b-input-group v-else>
                <cleave
                  id="dataInicial"
                  type="text"
                  v-model="pesquisar.dataInicial"
                  placeholder="DD/MM/AAAA"
                  class="form-control cleave-height"
                  :options="options.cleaveDate"
                  :raw="false"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    size="sm"
                    button-variant="primary"
                    class="datePickerWithInput"
                    right
                    button-only
                    v-model="dataInicialDatePicker"
                    :hide-header="true"
                    :initial-date="new Date()"
                    label-help=""
                    label-calendar="Calendário"
                    label-close-button="Fechar"
                    label-current-month="Mês atual"
                    label-next-decade="Próxima década"
                    label-next-month="Próximo mês"
                    label-next-year="Próximo ano"
                    label-prev-decade="Década anterior"
                    label-prev-month="Mês anterior"
                    label-prev-year="Ano anterior"
                    label-select-month="Selecione o mês"
                    label-select-year="Selecione o ano"
                    label-selected="Selecionado"
                    label-no-date-selected="Nenhuma data selecionada"
                    label-reset-button="Resetar"
                    label-today-button="Hoje"
                    label-today="Hoje"
                    locale="pt-br"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                    aria-controls="dataInicial"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Data Final" label-for="dataFinal">
              <validation-provider
                #default="{ errors }"
                name="Data Final"
                :rules="{
                  dataPassada: {
                    value: pesquisar.dataFinal,
                    dataParametro: pesquisar.dataInicial,
                    stringParametro: 'data inicial',
                  },
                }"
              >
                <b-skeleton v-if="isBusy" type="input" />
                <b-input-group v-else>
                  <cleave
                    id="dataFinal"
                    type="text"
                    v-model="pesquisar.dataFinal"
                    placeholder="DD/MM/AAAA"
                    class="form-control cleave-height"
                    :state="errors[0] ? false : null"
                    :options="options.cleaveDate"
                    :raw="false"
                    :rules="{
                      dataPassada: {
                        value: pesquisar.dataInicial,
                        dataParametro: pesquisar.dataFinal,
                        stringParametro: 'data de inicial',
                      },
                    }"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      size="sm"
                      button-variant="primary"
                      class="datePickerWithInput"
                      right
                      button-only
                      v-model="dataFinalDatePicker"
                      :state="errors[0] ? false : null"
                      :hide-header="true"
                      :initial-date="new Date()"
                      label-help=""
                      label-calendar="Calendário"
                      label-close-button="Fechar"
                      label-current-month="Mês atual"
                      label-next-decade="Próxima década"
                      label-next-month="Próximo mês"
                      label-next-year="Próximo ano"
                      label-prev-decade="Década anterior"
                      label-prev-month="Mês anterior"
                      label-prev-year="Ano anterior"
                      label-select-month="Selecione o mês"
                      label-select-year="Selecione o ano"
                      label-selected="Selecionado"
                      label-no-date-selected="Nenhuma data selecionada"
                      label-reset-button="Resetar"
                      label-today-button="Hoje"
                      label-today="Hoje"
                      locale="pt-br"
                      :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                      aria-controls="dataFinal"
                    />
                  </b-input-group-append>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-input-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Órgão" label-for="orgao">
              <b-skeleton v-if="isBusy" type="input" />
              <v-select-pt
                :clearable="false"
                v-else
                :reduce="(opcao) => opcao.id"
                id="orgao"
                :options="opcoesOrgao"
                v-model="pesquisar.orgaoId"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </validation-observer>
      <b-row id="botoes" class="justify-space-between">
        <b-col md="2" class="d-flex justify-content-start text-center align-items-center">
          <small v-if="!isBusy && totalRows">Total de Renegociações:</small>
          <b-skeleton v-else-if="isBusy" class="w-50 mr-25 mb-0" />
          <b-badge v-if="!isBusy && totalRows" pill variant="primary" class="ml-25">{{ totalRows }}</b-badge>
          <b-skeleton v-else-if="isBusy" class="w-10 mb-0 p-50" />
        </b-col>
        <b-col md="3" class="d-flex justify-content-end">
          <b-button variant="primary" @click="carregarGrid" :disabled="isBusy" class="mr-1">
            <feather-icon icon="SearchIcon" />
            <span class="align-middle ml-50">Prévia</span>
          </b-button>
          <b-dropdown :disabled="isBusy" variant="outline-primary">
            <template #button-content> <FeatherIcon icon="DownloadIcon" /> Exportar </template>
            <b-dropdown-item @click="exportarPdf">
              <b-icon icon="file-earmark-text" />
              PDF
            </b-dropdown-item>
            <b-dropdown-item @click="exportarXlsx">
              <b-icon icon="file-earmark-spreadsheet" />
              CSV
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row id="tabela">
        <b-col cols="12" v-if="totalRows > 0 && !isBusy">
          <b-table
            ref="tabelaUser"
            :per-page="perPage"
            :current-page="currentPage"
            responsive
            :fields="fields"
            :items="items"
            :busy="isBusy"
            :sort-by.sync="sortBy"
            :sort-direction="sortDirection"
            class="header-table mt-1"
            foot-clone
          >
            <template #foot()="data">
              <i></i>
            </template>
            <template #foot(nomeCompleto)="">
              <span class="text-bold">Valor total:</span>
            </template>
            <template #foot(valorParcela)="">
              <span>{{ totalValorParcela }}</span>
            </template>
            <template #foot(valorAntecipacao)="">
              <span>{{ totalValorAntecipacao }}</span>
            </template>
            <template #foot(juros)="">
              <span>{{ totalJuros }}</span>
            </template>
            <template #foot(amortizacao)="">
              <span>{{ totalAmortizacao }}</span>
            </template>
          </b-table>
          <b-card-body v-if="totalRows > 0" class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              style="align-items: center"
              label="Por Página"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
            </b-form-group>
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-card-body>
        </b-col>
        <b-col cols="12" v-else-if="isBusy">
          <b-skeleton-table class="custom-skeleton-table" :rows="1" :columns="7"> </b-skeleton-table>
        </b-col>
      </b-row>
    </b-card>
    <relatorioRenegociacaoRetrato id="exportarPDF" ref="exportarPDF" :parametro="parametroPDF" />
  </div>
</template>
<script>
  import { VueSelect } from 'vue-select'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import { formatarValor, formatarParaNumber, formatarData } from '@/utils/Functions/Formatacao/Formatacao'
  import * as XLSX from 'xlsx'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import VueHtml2pdf from 'vue-html2pdf'
  import relatorioRenegociacaoRetrato from './components/relatorioRenegociacaoRetrato.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { dataPassada } from '@validations'

  export default {
    components: {
      useJwt,
      VueSelect,
      Cleave,
      VueHtml2pdf,
      relatorioRenegociacaoRetrato,
      ValidationProvider,
      ValidationObserver,
      dataPassada,
    },
    props: {
      userData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        sortBy: 'name',
        sortDirection: 'asc',
        nomeRelatorio: 'Relatorio de Renegociação De Antecipações',
        opcoesPesquisa: [
          { label: 'Nome', id: 'nome' },
          { label: 'Contrato', id: 'contrato' },
        ],
        opcoesOrgao: [{ label: 'Todos', id: null }],
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        pesquisar: {
          tipoOpcoes: 'nome',
          descricao: '',
          orgaoId: null,
          dataInicial: '',
          dataFinal: '',
        },
        dataInicialDatePicker: '',
        dataFinalDatePicker: '',
        isBusy: false,
        fields: [
          {
            key: 'nomeCompleto',
            label: 'Nome',
            sortable: true,
          },
          {
            key: 'codigo',
            label: 'Contrato',
            sortable: true,
          },
          {
            key: 'parcelasAntecipadas',
            label: 'Parcelas Antecipadas',
          },
          {
            key: 'valorParcela',
            label: 'Valor da Parcela',
            sortable: true,
            formatter: formatarValor,
          },
          {
            key: 'valorAntecipacao',
            label: 'Valor de Antecipação',
            sortable: true,
            formatter: formatarValor,
          },
          {
            key: 'juros',
            label: 'Juros',
            sortable: true,
            formatter: formatarValor,
          },
          {
            key: 'amortizacao',
            label: 'Amortização',
            sortable: true,
            formatter: formatarValor,
          },
        ],
        fieldsPdf: [
          {
            key: 'nomeCompleto',
            label: 'Nome',
          },
          {
            key: 'codigo',
            label: 'Contrato',
          },
          {
            key: 'parcelasAntecipadas',
            label: 'Parcelas Antecipadas',
          },
          {
            key: 'valorParcela',
            label: 'Valor da Parcela',
            formatter: formatarValor,
          },
          {
            key: 'valorAntecipacao',
            label: 'Valor de Antecipação',
            formatter: formatarValor,
          },
          {
            key: 'juros',
            label: 'Juros',
            formatter: formatarValor,
          },
          {
            key: 'amortizacao',
            label: 'Amortização',
            formatter: formatarValor,
          },
        ],
        items: [],
        totalRows: null,
        parametroPDF: {
          dados: [],
          colunas: [],
          totalizadores: {
            totalValorParcela: 0,
            totalValorAntecipacao: 0,
            totalJuros: 0,
            totalAmortizacao: 0,
          },
        },
      }
    },
    mounted() {
      this.carregarOrgaos()
    },
    methods: {
      carregarOrgaos() {
        if (this.userData.institutoSelecionado) {
          useJwt
            .get(`cadastro/orgao/buscaPorInstitutoId/${this.userData.institutoSelecionado}`)
            .then((response) => {
              const orgaos = response.data

              this.opcoesOrgao = [
                { label: 'Todos', id: null },
                ...orgaos.map((orgao) => {
                  return { label: orgao.nome, id: orgao.id }
                }),
              ]
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      carregarGrid() {
        const filtros = {
          ...this.pesquisar,
          dataInicial: this.pesquisar.dataInicial != null ? this.pesquisar.dataInicial : null,
          dataFinal: this.pesquisar.dataFinal != null ? this.pesquisar.dataFinal : null,
          orgaoId: this.pesquisar.orgaoId ? this.pesquisar.orgaoId : 0,
          institutoId: this.userData.institutoSelecionado,
        }
        this.isBusy = true
        useJwt
          .post('relatorio/GetRelatorioRenegociacaoDividas', filtros)
          .then(async (response) => {
            this.items = response.data.map((item) => {
              return {
                ...item,
                valorParcela: item.valorParcela ? item.valorParcela : 0,
                valorAntecipacao: item.valorAntecipacao ? item.valorAntecipacao : 0,
                juros: item.juros ? item.juros : 0,
                amortizacao: item.amortizacao ? item.amortizacao : 0,
                parcelasAntecipadas:
                  item.parcelasAntecipadas == 0
                    ? `${item.numeroParcelas}/${item.numeroParcelas}`
                    : `${item.parcelasAntecipadas}/${item.numeroParcelas}`,
              }
            })
            await this.defineTotalizadores(response.data)

            const totalizadores = {
              totalValorParcela: this.totalValorParcela,
              totalValorAntecipacao: this.totalValorAntecipacao,
              totalJuros: this.totalJuros,
              totalAmortizacao: this.totalAmortizacao,
            }
            this.parametroPDF = {
              dados: this.items,
              colunas: this.fieldsPdf,
              institutoNome: this.userData.Instituto.nome,
              totalizadores,
            }
            this.totalRows = response.data.length
            if (this.totalRows == 0) {
              this.$message.warning('Nenhuma informação encontrada')
            }
          })
          .catch((error) => {
            console.error(error)
            this.items = []
            this.totalRows = 0
            this.$message.warning('Nenhuma informação encontrada')
          })
          .finally(() => {
            this.isBusy = false
          })
      },

      async defineTotalizadores(data) {
        this.totalValorParcela = formatarValor(data.reduce((acc, item) => acc + formatarParaNumber(item.valorParcela), 0))
        this.totalValorAntecipacao = formatarValor(
          data.reduce((acc, item) => acc + formatarParaNumber(item.valorAntecipacao), 0),
        )
        this.totalAmortizacao = formatarValor(data.reduce((acc, item) => acc + formatarParaNumber(item.amortizacao), 0))
        this.totalJuros = formatarValor(data.reduce((acc, item) => acc + formatarParaNumber(item.juros), 0))
      },

      async exportarXlsx() {
        this.isBusy = true
        if (!this.totalRows) {
          this.$message.warning('Nenhuma informação encontrada')
          this.isBusy = false
          return
        }

        const data = this.items.map((item) => ({
          Nome: item.nomeCompleto ? item.nomeCompleto : 'NOME NÃO ENCONTRADO',
          Contrato: item.codigo,
          'Valor da Parcela': formatarValor(item.valorParcela),
          'Valor de Antecipação': formatarValor(item.valorAntecipacao),
          'Parcelas Antecipadas': item.parcelasAntecipadas,
          Juros: formatarValor(item.juros),
          Amortização: formatarValor(item.amortizacao),
        }))
        // Usando os dados e colunas para criar a planilha
        const ws = XLSX.utils.json_to_sheet(data)

        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Relatorio')

        // Salvando o arquivo
        XLSX.writeFile(wb, 'Relatorio.xlsx')
        this.isBusy = false
      },

      async exportarPdf() {
        if (!this.totalRows) {
          this.$message.warning('Nenhuma informação encontrada')
          return
        }

        if (this.$refs.tabelaUser) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2Pdfportrait.$el.innerHTML
          let nomeInstituto = this.userData.Instituto.nome
          let periodoFiltro = ''
          let nomeRelatorio = this.nomeRelatorio
          let informacoesInstituto = `
                  ${this.userData.Instituto.logradouro},
                  ${this.userData.Instituto.numero},
                  ${this.userData.Instituto.bairro},
                  ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                  E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            null,
            this.userData.Instituto.id,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
    },
    watch: {
      dataInicialDatePicker(newVal) {
        this.pesquisar.dataInicial = formatarData(this.dataInicialDatePicker)
      },
      dataFinalDatePicker(newVal) {
        this.pesquisar.dataFinal = formatarData(this.dataFinalDatePicker)
      },
    },
  }
</script>

<style lang="css">
  .header-table th {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
    background-color: #ffffff !important;
  }
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  [dir] .btn-outline-primary:not(:disabled):not(.disabled).active,
  [dir] .btn-outline-primary:not(:disabled):not(.disabled):focus {
    background-color: rgba(57, 73, 171, 0.04) !important;
    color: #3949ab !important;
  }
  .btn-outline-primary:not(:disabled):not(.disabled):active,
  [dir] .btn-outline-primary:not(:disabled):not(.disabled).active,
  [dir] .btn-outline-primary:not(:disabled):not(.disabled):focus {
    background-color: rgba(57, 73, 171, 0.04) !important;
    color: #3949ab !important;
  }
</style>
